import React from 'react';
import '../App.css';
import Footer from '../components/footer/Footer';
import HomeSection from '../components/home_section/HomeSection';
import Navbar from '../components/navbar/Navbar';
import ProjectsSection from '../components/projects_section/ProjectsSection';
import Separator from '../components/separator/Separator';


function HomePage() {

  return (
    <>
      <Navbar />

      <HomeSection/>

      <Separator/>

      <ProjectsSection/>

      <Footer/>

    </>
  );
}

export default HomePage;
