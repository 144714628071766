import React, { useState } from 'react'
import './Footer.css'

function Footer() {

    return (
        <div className='footer'>

            <p>© 2023</p>
            <p className='footer-text'>Theme inspired by <a href="https://www.djordjeatlialp.de/">Djordje Atlialp</a></p>

        </div>

    )
}

export default Footer