import React, { useState } from 'react';
import ProjectShowcase from './ProjectShowcase';
import './ProjectsSection.css';

import mapifyImage from '../../images/mapify_img.png';

function ProjectsSection() {

    return (
        <div className='projectssection'>

            <ProjectShowcase
                image={mapifyImage}
                link='https://main.dg3ww6uf22b0w.amplifyapp.com/' 
                title='Mapify' 
                description="Mapify is an attempt at creating a world map of songs, so that you can explore them not only audially, but also spatially. Click to learn more about your tastes in music, and discover new artists and genres in the same way you would explore a territorial map!"
                technologies={['CloudFormation', 'React', 'Kedro', 'SKLearn', 'D3.js']} />
            {/* <ProjectShowcase image='logo512.png' url='https://mapify.maxime-steinmetz.com' title='New Project' description='Mapify desc...'/> */}

        </div>

    )
}

export default ProjectsSection