import React from 'react'
import './ProjectShowcase.css'

// import {Text, StyleSheet} from 'react-native';

// const BoldAndBeautiful = () => {
//     return (
//         <Text style={styles.baseText}>
//             I am bold
//             <Text style={styles.innerText}> and red</Text>
//         </Text>
//     );
// };

// const styles = StyleSheet.create({
//     baseText: {
//         fontWeight: 'bold',
//     },
//     innerText: {
//         color: 'red',
//     },
// });

function ProjectShowcase({ image, link, title, description, technologies = [] }) {

    return (
        <div className='projectshowcase'>


            <div className='projectshowcase-container'>

                <div className='crop'>
                    <a href={link} target="_blank">
                        <img src={image} />
                    </a>
                </div>

                <div className='projectshowcase-description' >
                    <h1><a className='projectshowcase-title' href={link} target='_blank'>{title}</a></h1>
                    <p>{description}</p>
                    {/* <Text>{description}</Text> */}
                    <p className='projectshowcase-technologies'>Technologies: {technologies.join(", ")}</p>
                </div>
            </div>



        </div>

    )
}

export default ProjectShowcase