import React from 'react'
import { Link } from 'react-router-dom'
import DropDownMenu from './DropDownMenu';
import './Navbar.css'
import Typewriter from 'typewriter-effect';

function Navbar({showDropDownMenu=false}) {

    return (
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo'>
                &gt; $ cd /maximest/
                </Link>
                <Typewriter options={{cursor: "█", loop: true}}
                    onInit={(typewriter) => {
                        typewriter.typeString('data_pipelines')
                        .pauseFor(5000)
                        .deleteAll()
                        .typeString('mlops_best_practices.md')
                        .pauseFor(5000)
                        .deleteAll()
                        .typeString('kedro')
                        .pauseFor(5000)
                        .deleteAll()
                        .typeString('docker')
                        .pauseFor(5000)
                        .deleteAll()
                        .typeString('aws')
                        .pauseFor(5000)
                        .deleteAll()
                        .typeString('mlflow')
                        .pauseFor(5000)
                        .deleteAll()
                        .typeString('serverless')
                        .pauseFor(5000)
                        .deleteAll()
                        .start();
                    }}
                    />
                {showDropDownMenu ? DropDownMenu() : <></>}
                
            </div>
        </nav>

    )
}

export default Navbar