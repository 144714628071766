import React, { useState } from 'react'
import './HomeSection.css'


function HomeSection() {

    return (
        <div className='homesection'>

            <div className='homesection-info-container'>

                <h1>Maxime Steinmetz</h1>
                <p>Data Science / Data Engineering / MLOps</p>

                <div className='homesection-contact-info'>
                    <a href='mailto:max.steinn@gmail.com'>
                        <i className="fa-regular fa-envelope" />
                    </a>
                    <a href='https://github.com/MaximeSteinmetz'>
                    <i className="fa-brands fa-github"/>
                    </a>
                    <a href='https://www.linkedin.com/in/maxime-steinmetz-220b1110b/'>
                    <i className="fa-brands fa-linkedin-in"/>
                    </a>
                </div>
            </div>





        </div>

    )
}

export default HomeSection