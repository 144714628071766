import React, { useState } from 'react'
import './Separator.css'

function Separator() {

    return (
        <div className='separator'>

            <div className='separator-container'>


                <p>Find below my pet projects. I like working on new technologies while building something useful and fun at the same time! </p>

            </div>
        </div>

    )
}

export default Separator